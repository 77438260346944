import request from '../utils/request';


export const fetchDataInit = query => {
    return request({
        url: process.env.VUE_APP_URL+'Dashboard/init',
        method: 'get',
        params: query
    });
};

