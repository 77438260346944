<template>
  <div>
    <el-row :gutter="20">
      <el-col :span="8">
        <el-card shadow="hover" class="mgb20" style="height:200px;">
          <div class="user-info">
            <img :src="UserInfo.Avatar" class="user-avator" alt/>
            <div class="user-info-cont">
              <div class="user-info-name">{{ UserInfo.Name }}</div>
              <div>权限：{{ UserInfo.Jurisdiction }}</div>
            </div>
          </div>
        </el-card>
        <el-card shadow="hover" style="height:600px;margin-bottom: 15px">
          <div class="grid-content grid-con-1" style="margin-bottom: 30px">
            <i class="el-icon-user-solid grid-con-icon"></i>
            <div class="grid-cont-right">
              <div class="grid-num">{{ UserNumber }}</div>
              <div>全部用户量</div>
            </div>
          </div>
          <div class="grid-content grid-con-1" style="margin-bottom: 30px">
            <i class="el-icon-s-data grid-con-icon"></i>
            <div class="grid-cont-right">
              <div class="grid-num">{{ PvDate }}</div>
              <div>今日访问</div>
            </div>
          </div>
          <div class="grid-content grid-con-1" style="margin-bottom: 30px">
            <i class="el-icon-warning grid-con-icon"></i>
            <div class="grid-cont-right">
              <div class="grid-num">{{ NotIntegral }}</div>
              <div>待归属贡献值</div>
            </div>
          </div>
          <div class="grid-content grid-con-1" style="margin-bottom: 30px">
            <i class="el-icon-success grid-con-icon"></i>
            <div class="grid-cont-right">
              <div class="grid-num">{{ UseIntegral }}</div>
              <div>已归属贡献值</div>
            </div>
          </div>


        </el-card>
      </el-col>
      <el-col :span="16">
        <el-row :gutter="20" class="mgb20">
          <el-col :span="8">
            <el-card shadow="hover" :body-style="{ padding: '0px' }">
              <div class="grid-content grid-con-1">
                <i class="el-icon-discount grid-con-icon"></i>
                <div class="grid-cont-right">
                  <div class="grid-num">{{ ToPv }}</div>
                  <div>总访问量</div>
                </div>
              </div>
            </el-card>
          </el-col>
          <el-col :span="8">
            <el-card shadow="hover" :body-style="{ padding: '0px' }">
              <div class="grid-content grid-con-2">
                <i class="el-icon-circle-check grid-con-icon"></i>
                <div class="grid-cont-right">
                  <div class="grid-num">{{ AuditNumber }}</div>
                  <div>待处理审核</div>
                </div>
              </div>
            </el-card>
          </el-col>
          <el-col :span="8">
            <el-card shadow="hover" :body-style="{ padding: '0px' }">
              <div class="grid-content grid-con-3">
                <i class="el-icon-circle-plus-outline grid-con-icon"></i>
                <div class="grid-cont-right">
                  <div class="grid-num">{{ SignNumber }}</div>
                  <div>待处理签署</div>
                </div>
              </div>
            </el-card>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="24">
            <el-card shadow="hover">
              <schart ref="line" class="schart" canvasId="line" :options="Pvoptions"></schart>
            </el-card>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="24">
            <el-card shadow="hover">
              <schart ref="bar" class="schart" canvasId="bar" :options="Rankingoptions"></schart>
            </el-card>
          </el-col>
        </el-row>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import Schart from "vue-schart";
import {fetchDataInit} from "../api/Dashboard";


export default {
  components: {Schart},
  data() {
    return {
      UserInfo: this.$store.state.UserInfo,
      name: localStorage.getItem("ms_username"),
      todoList: [],
      UserNumber: 0,
      SignNumber: 0,
      AuditNumber: 0,
      UseIntegral: 0,
      NotIntegral: 0,
      PvDate: 0,
      ToPv: 0,
      ApprovalData: [],
      ConfirmData: [],
      Pvoptions: {},
      Rankingoptions: {}
    };
  },
  mounted() {
    this.getdata()
    // this.visitSum()
    // this.StatisticsData()
    // this.RankingData()

  },
  methods: {
    getdata() {
      fetchDataInit().then(res => {
        this.SignNumber = res.SignNumber
        this.AuditNumber = res.AuditNumber
        this.NotIntegral = res.NotIntegral
        this.UseIntegral = res.UseIntegral
        this.UserNumber = res.UserNumber
        this.PvDate = res.PvDate
        this.ToPv = res.ToPv
        this.RankingData(res.RankingIntegral)
        this.StatisticsData(res.RankingPv)
      })
    },
    RankingData(sum) {
      let labels = []
      let data = []
      for (let item in sum) {
        let Name = sum[item].Name
        let TotalIntegral = sum[item].TotalIntegral
        labels.push(Name)
        data.push(TotalIntegral)
      }
      let options = {
        type: "bar",
        title: {
          text: "贡献值排名",
        },
        xRorate: 25,
        labels: labels,
        datasets: [
          {
            label: "贡献值",
            data: data,
          },
        ],
      };
      this.Rankingoptions = options
    },
    StatisticsData(sum) {
      let labels = []
      let data = []
      for (let item in sum) {
        let Time = sum[item].TimeHours + "日" + sum[item].TimeDate + "时"
        let Number = sum[item].Number
        labels.push(Time)
        data.push(Number)
      }
      let options = {
        type: "line",
        title: {
          text: "网站访问量",
        },
        labels: labels,
        datasets: [
          {
            label: "pv",
            data: data,
          },
        ],
      };
      this.Pvoptions = options
    },
  }

};
</script>

<style scoped>

.el-row {
  margin-bottom: 20px;
}

.grid-content {
  display: flex;
  align-items: center;
  height: 100px;
}

.grid-cont-right {
  flex: 1;
  text-align: center;
  font-size: 14px;
  color: #999;
}

.grid-num {
  font-size: 30px;
  font-weight: bold;
}

.grid-con-icon {
  font-size: 50px;
  width: 100px;
  height: 100px;
  text-align: center;
  line-height: 100px;
  color: #fff;
}

.grid-con-1 .grid-con-icon {
  background: rgb(45, 140, 240);
}

.grid-con-1 .grid-num {
  color: rgb(45, 140, 240);
}

.grid-con-2 .grid-con-icon {
  background: rgb(100, 213, 114);
}


.grid-con-2 .grid-num {
  color: rgb(100, 213, 114);
}

.grid-con-3 .grid-con-icon {
  background: #ffd11e;
}

.grid-con-3 .grid-num {
  color: rgb(242, 94, 67);
}

.user-info {
  display: flex;
  align-items: center;
  padding-bottom: 20px;
  border-bottom: 2px solid #ccc;
  margin-bottom: 20px;
}

.user-avator {
  width: 120px;
  height: 120px;
  border-radius: 50%;
}

.user-info-cont {
  padding-left: 50px;
  flex: 1;
  font-size: 12px;
  color: #999;
}

.user-info-cont div:first-child {
  font-size: 17px;
  color: #222;
}

.user-info-list {
  font-size: 14px;
  color: #999;
  line-height: 25px;
}

.user-info-list span {
  margin-left: 70px;
}

.mgb20 {
  margin-bottom: 20px;
}

.todo-item {
  font-size: 14px;
}

.todo-item-del {
  text-decoration: line-through;
  color: #999;
}

.schart {
  width: 100%;
  height: 300px;
}
</style>
